
import { Patient } from '@/models/Patient';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EmptyState from '@/components/EmptyState.vue';
import HeadingComponent from '../../HeadingComponent.vue';
import LoadingComponent from '../../LoadingComponent.vue';
import DaysPickerWithWeekDays from './common/DaysPickerWithWeekDays.vue';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import MealFoodsCard from './MealFoodsCard/MealFoodsCard.vue';
import AlternativeMealModal from './AlternativeMealModal.vue';
import {
  Diet,
  DietDay,
  DietDayFormDto,
  MenuMeal,
  MenuMealDto,
  updateMenuMealFromMenuMealDto,
} from '@/models/NutritionalPlan';
import NutritionalPlanMenuCard from './NutritionalPlanMenuCard.vue';
import DietDayConfirmModal from './DietDayConfirmModal.vue';
import MenuCreateFormModal from './MenuCreateFormModal.vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    EmptyState,
    DaysPickerWithWeekDays,
    MealFoodsCard,
    NutritionalPlanMenuCard,
    DietDayConfirmModal,
    MenuCreateFormModal,
    AlternativeMealModal,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();
    const route = useRoute();
    const updateNutritionPlanModalRef = ref();
    const alternativeMealModalRef = ref();
    const menuCreateModalRef = ref();
    const loadingNutritionalPlans = ref(false);
    const patientId = computed(() => props.patient.id);
    const isEditModal = ref(false);
    const selectedDateIndex = ref(0);
    const diet: Ref<Diet | undefined> = ref();
    const mealsBySelectedDay: Ref<MenuMeal[]> = ref([]);
    const dietDay: Ref<DietDay | undefined> = ref();
    const showMenu = computed(() => {
      if (!diet.value) {
        return false;
      }
      if (diet.value.dietDays.length === 0) {
        return false;
      }

      const dietDay = diet.value.dietDays.find((dietDay) => dietDay.day === selectedDateIndex.value + 1);

      if (!dietDay) {
        return false;
      }

      const hasMeals = dietDay?.meals.length > 0;

      return hasMeals;
    });

    const onDayPickerChanged = async (dayIndex: number) => {
      selectedDateIndex.value = dayIndex;
      await fetchDiet();
    };

    const getMealsBySelectedDay = () => {
      if (selectedDateIndex.value === null && diet.value === null) {
        mealsBySelectedDay.value = [];
      }

      dietDay.value = diet.value?.dietDays.find((diet) => diet.day == selectedDateIndex.value + 1);

      mealsBySelectedDay.value = dietDay.value?.meals ?? [];
    };

    const fetchDiet = async () => {
      loadingNutritionalPlans.value = true;
      const result = await nutritionalPlanService.findDiet(patientId.value);

      if (!(result instanceof ServiceError)) {
        diet.value = result;

        getMealsBySelectedDay();
      }

      loadingNutritionalPlans.value = false;
    };

    const onEditDietDay = async (dietFormDto: DietDayFormDto, diet: Diet) => {
      updateNutritionPlanModalRef.value?.openDialog(dietFormDto, diet);
    };

    const onNewMenu = async () => {
      menuCreateModalRef.value?.openDialog(diet.value);
    };

    onMounted(async () => {
      await fetchDiet();
    });

    async function onMenuFoodUpdate(updatedMenuMeal: MenuMealDto) {
      if (dietDay.value) {
        const result = await nutritionalPlanService.updateMenuMeal(
          patientId.value,
          dietDay.value?.day,
          updatedMenuMeal.id,
          updateMenuMealFromMenuMealDto(updatedMenuMeal.mealDetails, updatedMenuMeal.name),
        );

        if (result instanceof ServiceError) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditMeal')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } else {
          await fetchDiet();
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditMeal')}`, life: 3000 });
        }
      }
    }

    const goToDailyTracking = () => {
      router.push(`/patient/${patientId.value}/follow-up/nutrition`);
    };

    const onCreateAlternativeMeal = async (mealId: string, mealName: string) => {
      alternativeMealModalRef.value?.openDialog(mealId, undefined, undefined, mealName, dietDay.value?.day);
    };

    return {
      loadingNutritionalPlans,
      route,
      updateNutritionPlanModalRef,
      alternativeMealModalRef,
      menuCreateModalRef,
      patientId,
      isEditModal,
      diet,
      selectedDateIndex,
      showMenu,
      mealsBySelectedDay,
      dietDay,

      fetchDiet,
      onEditDietDay,
      onDayPickerChanged,
      onNewMenu,
      onMenuFoodUpdate,
      goToDailyTracking,
      onCreateAlternativeMeal,
    };
  },
});
