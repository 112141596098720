import moment from 'moment';
import { Genders } from './Genders';
import { DietType } from './MedicalTest';
import { Intolerances } from './PatientInfo';
import { UserTableDto } from './User';
import { isObjectValid, required, requiredIfCondition, ValidationResult } from '@/validation/form';

class MenuDayCreateFormDto {
  weeks: number;
  startDate: string;

  constructor(number: number, startDate: string) {
    this.weeks = number;
    this.startDate = startDate;
  }
}

class NutritionalPlanCreateFormDto {
  name: string;
  constructor(nutritionalPlan: NutritionalPlan) {
    this.name = nutritionalPlan.name;
  }
}

class NutritionalPlanFormDto {
  id: string;
  name: string;
  gender: Genders;
  professional: UserTableDto;
  weight: number;
  height: number;
  birthdate: any;
  foodIntolerances: Intolerances[];
  activityFrequency: number;
  dietType: DietType;
  numberOfMeals: number;
  meals: Meal[];
  currentCalories: number;
  proteinShare: number;
  fatShare: number;
  carbohydratesShare: number;
  enabled: boolean;

  constructor(nutritionalPlan: NutritionalPlan) {
    this.id = nutritionalPlan.id;
    this.name = nutritionalPlan.name;
    this.gender = nutritionalPlan.gender;
    this.professional = nutritionalPlan.professional;
    this.weight = nutritionalPlan.weight;
    this.height = nutritionalPlan.height;
    this.birthdate = nutritionalPlan.birthdate ? new Date(nutritionalPlan.birthdate) : undefined;
    this.foodIntolerances = nutritionalPlan.foodIntolerances ?? [];
    this.activityFrequency = nutritionalPlan.activityFrequency;
    this.dietType = nutritionalPlan.dietType;
    this.numberOfMeals = nutritionalPlan.numberOfMeals ?? nutritionalPlan.meals ? nutritionalPlan.meals.length : 0;
    this.meals = nutritionalPlan.meals;
    this.currentCalories = nutritionalPlan.currentCalories;
    this.proteinShare = nutritionalPlan.proteinShare;
    this.fatShare = nutritionalPlan.fatShare;
    this.carbohydratesShare = nutritionalPlan.carbohydratesShare;
    this.enabled = nutritionalPlan.enabled ? nutritionalPlan.enabled : false;
  }
}

function updateNutritionalPlanData(nutritionalPlan: NutritionalPlanFormDto) {
  const updateData = { ...nutritionalPlan };
  updateData.birthdate = moment(updateData.birthdate).format('YYYY-MM-DDTHH:mm:ss');

  return updateData;
}

enum MacroType {
  Protein = 'PROTEIN',
  Carbs = 'CARBS',
  Fat = 'FAT',
}

function getMacroIcon(macro: MacroType) {
  if (macro === MacroType.Fat) {
    return 'icon-fats';
  }
  if (macro === MacroType.Protein) {
    return 'icon-proteins';
  }

  return 'icon-hc';
}

interface MenuDay {
  date: string;
  meals: Meal[];
  nutritionalPlan: NutritionalPlan;
  nutritionalPlanId: string;
  professional: UserTableDto;
}
enum MealType {
  BREAKFAST = 'BREAKFAST',
  MID_MORNING_SNACK = 'MID_MORNING_SNACK',
  MID_AFTERNOON_SNACK = 'MID_AFTERNOON_SNACK',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
}

interface MealDetails {
  macroType: MacroType;
  share: number;
  bites: number;
  calories: number;
  quantity: number;
}

interface Meal {
  name: string;
  share: number;
  mealType: MealType;
  mealDetails: MealDetails[];
}

class MealFormDto {
  name: string;
  share: number;
  mealType: MealType;
  mealDetails: MealDetails[];
  constructor(meal: Meal) {
    this.name = meal.name;
    this.share = meal.share;
    this.mealType = meal.mealType;
    this.mealDetails = meal.mealDetails;
  }
}

interface NutritionalPlan {
  id: string;
  patientId: string;
  name: string;
  gender: Genders;
  professional: UserTableDto;
  weight: number;
  height: number;
  birthdate: string;
  foodIntolerances: Intolerances[];
  basalCalories: number;
  activityFrequency: number;
  totalCalories: number;
  dietType: DietType;
  numberOfMeals: number;
  meals: Meal[];
  currentCalories: number;
  proteinShare: number;
  fatShare: number;
  carbohydratesShare: number;
  defaultMeals: MenuMeal[];
  creationDate: string;
  enabled: boolean | null;
}

export enum FoodCategory {
  MEATS_AND_DERIVATIVES = 'MEATS_AND_DERIVATIVES',
  DAIRY = 'DAIRY',
  FISH_AND_SEAFOOD = 'FISH_AND_SEAFOOD',
  EGGS = 'EGGS',
  CEREALS_AND_DERIVATES = 'CEREALS_AND_DERIVATES',
  LEGUMES = 'LEGUMES',
  FRUITS = 'FRUITS',
  VEGETABLES_AND_GREENS = 'VEGETABLES_AND_GREENS',
  DRIED_FRUITS_AND_SEEDS = 'DRIED_FRUITS_AND_SEEDS',
  OILS_AND_FATS = 'OILS_AND_FATS',
  SWEETS_AND_TREATS = 'SWEETS_AND_TREATS',
  DRINKS = 'DRINKS',
  HERBS_AND_SPICES = 'HERBS_AND_SPICES',
  PRE_COOKED_DISHES = 'PRE_COOKED_DISHES',
  BREAD_AND_DERIVATIVES = 'BREAD_AND_DERIVATIVES',
  OTHERS = 'OTHERS',
}

interface Food {
  id: string;
  name: string;
  category: FoodCategory;
  mealTypes: MealType[];
  quantity: number;
  calories: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  proteinBites: number;
  fatBites: number;
  carbohydratesBites: number;
  units: string;
  macroType: MacroType;
  foodIntolerances: Intolerances[];
  foodExclusions: Intolerances[];
  tier: number;
  unitWeight: number;
  deactivated: boolean;
  healzValue: boolean;
  creationDate: string;
}

interface CreateFoodFormValidity {
  name: ValidationResult;
  category: ValidationResult;
  mealTypes: ValidationResult;
  units: ValidationResult;
  unitWeight: ValidationResult;
  calories: ValidationResult;
  protein: ValidationResult;
  fat: ValidationResult;
  carbohydrates: ValidationResult;
}

function createFoodForm(food: FoodDto): CreateFoodFormValidity {
  const name = required(food.name);
  const category = required(food.category);
  const mealTypes = required(food.mealTypes);
  const units = required(food.units);
  const unitWeight = requiredIfCondition(food.unitWeight, food.units === 'u');
  const calories = required(food.calories);
  const protein = required(food.protein);
  const fat = required(food.fat);
  const carbohydrates = required(food.carbohydrates);

  return {
    name,
    category,
    mealTypes,
    units,
    unitWeight,
    calories,
    protein,
    fat,
    carbohydrates,
  };
}

function isFormCreateValid(form: CreateFoodFormValidity) {
  return isObjectValid<CreateFoodFormValidity>(form);
}

interface MenuMealDetail {
  bites: number;
  foodId: string;
  food: Food;
  accountId: string;
}

interface MenuMeal {
  id: string;
  name: string | undefined;
  proteinBites: number | null;
  fatBites: number | null;
  carbohydratesBites: number | null;
  mealType: MealType;
  mealDetails: MenuMealDetail[];
  completed: boolean;
}

interface DietDay {
  day: number;
  nutritionalPlanId: string;
  nutritionalPlan: NutritionalPlan;
  meals: MenuMeal[];
}

interface Diet {
  id: string;
  professional: UserTableDto;
  dietDays: DietDay[];
}

interface MenuMealDetailFoodDto {
  id: string;
  name: string;
  quantity: number;
  calories: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  proteinBites: number;
  fatBites: number;
  carbohydratesBites: number;
  units: string;
  foodIntolerances?: Intolerances[];
}

export enum FoodUnit {
  'g' = 'g',
  'ml' = 'ml',
  'u' = 'u',
}

class FoodDto {
  id: string;
  name: string;
  category: FoodCategory;
  mealTypes: MealType[];
  units: FoodUnit;
  foodIntolerances: Intolerances[];
  foodExclusions: Intolerances[];
  tier: number;
  unitWeight: number;
  calories: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  deactivated: boolean;

  constructor(food: Food) {
    this.id = food.id;
    this.name = food.name;
    this.category = food.category;
    this.mealTypes = food.mealTypes;
    this.units = food.units as FoodUnit;
    this.foodIntolerances = food.foodIntolerances;
    this.foodExclusions = food.foodExclusions;
    this.tier = food.tier;
    this.unitWeight = food.unitWeight;
    this.calories = food.calories;
    this.protein = food.protein;
    this.fat = food.fat;
    this.carbohydrates = food.carbohydrates;
    this.deactivated = food.deactivated;
  }
}

interface MenuMealDetailDto {
  bites: number;
  foodId: string;
  food: MenuMealDetailFoodDto;
  accountId: string;
}

class MenuMealDto {
  id: string;
  name: string | undefined;
  proteinBites: number | null;
  fatBites: number | null;
  mealType: MealType | null;
  carbohydratesBites: number | null;
  mealDetails: MenuMealDetailDto[];
  completed: boolean;

  constructor(menuMeal: MenuMeal) {
    this.id = menuMeal.id;
    this.name = menuMeal.name;
    this.proteinBites = menuMeal.proteinBites;
    this.fatBites = menuMeal.fatBites;
    this.carbohydratesBites = menuMeal.carbohydratesBites;
    this.mealType = menuMeal.mealType;
    this.mealDetails = menuMeal.mealDetails;
    this.completed = menuMeal.completed;
  }
}

interface UpdateMenuMealDto {
  name: string | undefined;
  mealDetails: MenuMealDetailDto[];
}

export function updateMenuMealFromMenuMealDto(menuMealDetails: MenuMealDetailDto[], name?: string): UpdateMenuMealDto {
  const newMenuMeal: UpdateMenuMealDto = {
    name: name,
    mealDetails: menuMealDetails,
  };
  return newMenuMeal;
}

class DietDayFormDto {
  nutritionalPlanId: string;
  constructor(nutritionalPlanId: string) {
    this.nutritionalPlanId = nutritionalPlanId;
  }
}

export {
  NutritionalPlan,
  Meal,
  MealDetails,
  MacroType,
  getMacroIcon,
  MealType,
  NutritionalPlanFormDto,
  NutritionalPlanCreateFormDto,
  MealFormDto,
  updateNutritionalPlanData,
  UpdateMenuMealDto,
  Diet,
  DietDay,
  MenuDay,
  Food,
  FoodDto,
  MenuMealDto,
  MenuMealDetailDto,
  MenuMeal,
  MenuMealDetail,
  DietDayFormDto,
  MenuDayCreateFormDto,
  createFoodForm,
  isFormCreateValid,
};
